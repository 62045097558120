<template>
  <div class="pt-30">
    <div class="content-box bg-white box-shadow mb-30">
      <div class="tabs">
        <!-- <div
          class="tab px-25 pt-20"
          :class="{ active: tabid == 0 }"
          @click="onTab(0)"
        >
          {{ $t("fiat_currency.onTab1") }}
        </div> -->

        <!-- USDT快捷賣出 換去個人中心裡面 -->
        <!-- <div
          class="tab px-25 pt-20"
          :class="{ active: tabid == 1 }"
          @click="onTab(1)"
        >
          {{ $t("fiat_currency.onTab2") }}
        </div> -->

        <div
          class="tab px-25 pt-20"
          :class="{ active: tabid == 2 }"
          @click="onTab(2)"
        >
          {{ $t("fiat_currency.onTab3") }}
        </div>

        <div
          class="tab px-25 pt-20"
          :class="{ active: tabid == 3 }"
          @click="onTab(3)"
        >
          {{ $t("fiat_currency.onTab4") }}
        </div>
      </div>
      <!-- 自选交易 -->
      <!-- <div class="box1" v-if="tabid == 0" v-loading="tradingLoading">
        <el-row
          :gutter="20"
          type="flex"
          justify="center"
          align="middle"
          class="font-14 color-gray px-20 py-15"
        >
          <el-col :span="5">{{ $t("fiat_currency.span1") }}</el-col>
          <el-col :span="4">{{ $t("fiat_currency.span2") }}</el-col>
          <el-col :span="6">{{ $t("fiat_currency.span3") }}</el-col>
          <el-col :span="3">{{ $t("fiat_currency.span4") }}</el-col>
          <el-col :span="3">{{ $t("fiat_currency.span5") }}</el-col>
          <el-col class="text-right" :span="3"
            >{{ $t("fiat_currency.span6")
            }}<span>0{{ $t("fiat_currency.span7") }}</span></el-col
          >
        </el-row>
        <el-row
          v-for="(item, index) in market"
          :key="index"
          :gutter="20"
          type="flex"
          justify="center"
          align="middle"
          class="market-body px-20 py-15 border-top"
        >
          <el-col :span="5">
            <div class="dealers">
              <el-image class="dealers_img" :src="aimg" fit="cover"> </el-image>
              <span>{{ item.nickname }}</span>
            </div>
          </el-col>
          <el-col :span="4">{{ item.balance }} USDT</el-col>
          <el-col :span="6">
            {{ item.min_money }} TWD ~ {{ item.max_money }} TWD
          </el-col>
          <el-col :span="3">
            <span class="price_style"> ${{ item.offer }} </span>
          </el-col>
          <el-col :span="3">
            <div class="bank_card">
              <i class="el-icon-bank-card"></i>
              <span>{{ $t("fiat_currency.bank") }}</span>
            </div>
          </el-col>
          <el-col class="deal text-right" :span="3">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-s-data"
              @click="open(item)"
              >{{ $t("fiat_currency.open") }}</el-button
            >
          </el-col>
        </el-row>
        <div class="pagination-outline">
          <div
            v-if="market.length < 1"
            class="py-50 font-14 color-i text-center"
          >
            No More
          </div>
          <div class="pagination-box" v-else>
            <el-pagination
              layout="prev, pager, next"
              background
              :total="total"
              :page-size="pageSize"
              :current-page="page"
              @current-change="pageFn1"
            >
            </el-pagination>
          </div>
        </div>
      </div> -->
      <!-- 自选交易 -end -->

      <!-- USDT快捷賣出 -->
      <!-- <div class="box2" v-if="tabid == 1">
        <div class="box-shadow box-form">
          <el-form
            ref="ruleForm"
            class="input-form mx-auto"
            v-loading="load2"
            :model="formData"
            :rules="rules"
            label-position="top"
          >
            <label class="label"
              ><span>*</span>{{ $t("fiat_currency.recycle.inp1") }}</label
            >
            <el-form-item prop="money">
              <el-input
                v-model="formData.money"
                prefix-icon="el-icon-money"
                :placeholder="$t('fiat_currency.recycle.plc1')"
                @input="moneyFn"
              />
            </el-form-item>

            <label class="label"
              ><span>*</span>{{ $t("fiat_currency.recycle.inp2") }}</label
            >
            <el-form-item>
              <el-select

                style="width: 100%"
                v-model="formData.coin"
                filterable
                clearable
                :placeholder="$t('fiat_currency.recycle.plc2')"
                @change="changeFnCoin"
              >
                <el-option
                  v-for="(item, index) in rate"
                  :key="index"
                  :label="item.name_text"
                  :value="item.id"
                >
                </el-option>

              </el-select>
            </el-form-item>

            <label class="label"
              ><span>*</span>{{ $t("fiat_currency.recycle.inp3") }}</label
            >
            <el-form-item>
              <el-input
                disabled
                v-model="formData.all"
                prefix-icon="el-icon-money"
                :placeholder="$t('fiat_currency.recycle.plc3')"
              />
            </el-form-item>
            <label class="label"
              ><span>*</span>{{ $t("fiat_currency.recycle.inp4") }}</label
            >
            <el-form-item prop="bank_id">
              <el-select
                style="width: 100%"
                v-model="formData.bank_id"
                filterable
                clearable
                :placeholder="$t('fiat_currency.recycle.plc4')"
              >
                <el-option
                  v-for="(item, index) in bank_list"
                  :key="index"
                  :label="item.coin+': '+item.account"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-s-promotion"
                class="w-100"
                native-type="button"
                @click="submitForm"
                :loading="loading"
                >{{ $t("fiat_currency.recycle.submitForm") }}</el-button
              >
            </el-form-item>
          </el-form>
          <div class="recycling_tips">
            <h5>{{ $t("fiat_currency.recycle.recycling_tips_title") }}：</h5>
            <p>
              1.
              {{ $t("fiat_currency.recycle.recycling_tip1") }}
            </p>
            <p>
              2.
              {{ $t("fiat_currency.recycle.recycling_tip2") }}
            </p>
            <p>
              3.
              {{ $t("fiat_currency.recycle.recycling_tip3") }}
            </p>
          </div>
        </div>
      </div> -->
      <!-- USDT快捷賣出 -end -->

      <!-- 对公转帐 -->
      <div class="box2 box3" v-if="tabid == 2">
        <!-- 驗證通過 -->
        <div class="box-shadow box-form" >
          <!-- v-show="corporate_account_power == 3" -->
          <div class="public_account">
            <dl>
              <dt>{{ $t("fiat_currency.public.dt") }}</dt>
              <dd>
                <h5>{{ $t("fiat_currency.public.dd1") }}:</h5>
                <p>{{ pub.bank_name }}</p>
              </dd>
              <dd>
                <h5>{{ $t("fiat_currency.public.dd2") }}:</h5>
                <p>{{ pub.bank_account }}</p>
              </dd>
              <dd>
                <h5>{{ $t("fiat_currency.public.dd3") }}:</h5>
                <p>{{ pub.bank_bank_name }}</p>
              </dd>
              <dd>
                <h5>{{ $t("fiat_currency.public.dd4") }}:</h5>
                <p>{{ pub.bank_bank_address }}</p>
              </dd>
              <dd>
                <h5>swift_code:</h5>
                <p>{{ pub.bank_swift_code }}</p>
              </dd>
            </dl>
          </div>
          <div class="submit_bank">
            <el-button
              type="primary"
              icon="el-icon-s-promotion"
              class="w-100"
              native-type="button"
              @click="bankFn"
              >{{ $t("fiat_currency.public.bankFn") }}</el-button
            >
          </div>
        </div>
        <!-- 3.17停止验证公账权限 -->
        <!-- 驗證失敗或者未提交 -->
        <!-- <div
          class="box-shadow box-form"
          v-show="corporate_account_power == 0 || corporate_account_power == 1"
        >
          <div class="public_account public_account_new">
            <h3>{{ $t("corporate_account_power.h_1") }}</h3>
            <p>{{ $t("corporate_account_power.p_1") }}</p>
          </div>
          <div class="submit_bank">
            <el-button
              type="primary"
              icon="el-icon-s-promotion"
              class="w-100"
              native-type="button"
              @click="corporateFn"
              >{{ $t("corporate_account_power.btn") }}</el-button
            >
          </div>
        </div> -->
        <!-- 驗證待審核通過 -->
        <!-- <div class="box-shadow box-form" v-show="corporate_account_power == 2">
          <div class="public_account public_account_new">
            <h3>{{ $t("corporate_account_power.h_2") }}</h3>
            <p>{{ $t("corporate_account_power.p_2") }}</p>
          </div>
        </div> -->
      </div>
      <!-- 对公转帐 -end -->

      <!-- 2023.5.31新增法幣在線支付 -->
      <div class="box2" v-if="tabid == 3">
        <div class="box-shadow box-form">
          <el-form
            ref="ruleForm_fiat"
            class="input-form mx-auto"
            v-loading="load2"
            :model="formData"
            :rules="rules"
            label-position="top"
          >
            <label class="label"
              ><span>*</span>{{ $t("fiat_currency.recycle.inp2") }}</label
            >
            <el-form-item prop="coin">
                              <!-- disabled -->
              <el-select
                style="width: 100%"
                v-model="formData.cointext"
                filterable
                clearable
                :placeholder="$t('fiat_currency.recycle.plc2')"
                @change="changeFnCoin"
              >
                <!-- <el-option
                  v-for="(item, index) in rate"
                  :key="index"
                  :label="index + ':' + item"
                  :value="item"
                >
                </el-option> -->

                <el-option
                  v-for="(item, index) in rate"
                  :key="index"
                  :label="item.name_text"
                  :value="item.id"
                >
                </el-option>

              </el-select>
            </el-form-item>

            <label class="label"
              ><span>*</span>{{ $t("fiat_currency.fait_pay.inp1") }}</label
            >
            <el-form-item prop="money">
              <el-input
                v-model="formData.money"
                prefix-icon="el-icon-money"
                :placeholder="$t('fiat_currency.fait_pay.plc1')"
                @input="moneyFn"
              />
            </el-form-item>

            <label class="label"
              ><span>*</span>{{ $t("fiat_currency.fait_pay.inp2") }}</label
            >
            <el-form-item>
              <el-input
                disabled
                v-model="formData.all"
                prefix-icon="el-icon-money"
                :placeholder="$t('fiat_currency.fait_pay.plc2')"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-s-promotion"
                class="w-100"
                native-type="button"
                @click="submitForm"
                :loading="loading"
                >{{ $t("fiat_currency.recycle.submitForm") }}</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 2023.5.31新增法幣在線支付 -end -->

      <!-- 购买弹窗 兌入 -->
      <el-dialog
        :title="$t('fiat_currency.dialog1.title')"
        :visible.sync="dialogVisible"
        width="900px"
      >
        <div class="buy_content" v-loading="dialog1">
          <div class="buy_content_left">
            <div class="dealers">
              <el-image class="dealers_img" :src="aimg" fit="cover"> </el-image>
              <span>{{ buyDetail.nickname }}</span>
              <div class="bank_card">
                <i class="el-icon-bank-card"></i>
                <span>{{ $t("fiat_currency.bank") }}</span>
              </div>
            </div>
            <div class="buy_content_mes">
              <div class="trade-notes-title">
                <h5>{{ $t("fiat_currency.dialog1.h5") }}：</h5>
                <p style="color: red">{{ $t("fiat_currency.dialog1.p1") }}</p>
              </div>
              <div class="trade-notes-text">
                <p>1、{{ $t("fiat_currency.dialog1.p2") }}</p>
                <p>2、{{ $t("fiat_currency.dialog1.p3") }}</p>
                <p>3、{{ $t("fiat_currency.dialog1.p4") }}</p>
              </div>
            </div>
          </div>
          <div class="buy_content_right">
            <div class="buy_content_right_item1">
              <div class="right_item_col">
                <span class="span1">{{
                  $t("fiat_currency.dialog1.span1")
                }}</span>
                <span class="span2">{{ buyDetail.balance }} USDT</span>
              </div>
              <div class="right_item_col">
                <span class="span1">{{
                  $t("fiat_currency.dialog1.span2")
                }}</span>
                <span class="span2"
                  >{{ buyDetail.min_money }} TWD ~
                  {{ buyDetail.max_money }} TWD</span
                >
              </div>
              <div class="right_item_col">
                <span class="span1">{{
                  $t("fiat_currency.dialog1.span3")
                }}</span>
                <span class="span2">${{ buyDetail.offer }}</span>
              </div>
            </div>
            <div class="buy_content_right_item2">
              <div class="buy_content_right_item2_top">
                <input
                  type="number"
                  :placeholder="$t('fiat_currency.dialog1.buynum')"
                  v-model="buynum"
                  @input="buynumFn"
                />
                <div class="inp-pos">
                  <span>USDT</span>
                  <span></span>
                  <span @click="allbuyFn">{{
                    $t("fiat_currency.dialog1.allbuyFn")
                  }}</span>
                </div>
              </div>
              <div class="buy_content_right_item2_btm">
                <span>{{ $t("fiat_currency.dialog1.pay_for") }}:</span>
                <strong>{{ pay_for }}</strong>
              </div>
            </div>
            <div class="buy_content_right_item3">
              <el-button
                type="success"
                size="small"
                icon="el-icon-s-promotion"
                @click="comeFn"
                >{{ $t("fiat_currency.dialog1.comeFn") }}</el-button
              >
            </div>
          </div>
        </div>

        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small">{{
            $t("contact_about.cancel")
          }}</el-button>
          <el-button
            type="primary"
            @click="submitRecharge"
            size="small"
            :loading="btn_load"
            >{{ $t("contact_about.confirm") }}</el-button
          >
        </span> -->
      </el-dialog>
      <!-- 购买弹窗 -end -->

      <!-- 对公转帐窗口 -->
      <el-dialog
        :title="$t('fiat_currency.dialog2.title')"
        :visible.sync="dialogVisible1"
        width="34%"
      >
        <el-form
          ref="ruleForm1"
          class="input-form mx-auto"
          :model="formDataBank"
          :rules="rules3"
          v-loading="load3"
          label-position="top"
        >
          <label class="label"
            ><span>*</span>{{ $t("fiat_currency.dialog2.label1") }}</label
          >
          <el-form-item prop="money">
            <el-input
              v-model="formDataBank.money"
              prefix-icon="el-icon-money"
              :placeholder="$t('fiat_currency.dialog2.placeholder1')"
            />
          </el-form-item>
          <label class="label"
            ><span>*</span>{{ $t("fiat_currency.dialog2.label2") }}</label
          >
          <el-form-item prop="image">
            <el-upload
              action=""
              accept="image/*"
              :multiple="false"
              :show-file-list="false"
              :before-upload="onUploadVoucher"
              class="w-100 text-center"
            >
              <el-button
                type="warning"
                icon="el-icon-upload"
                plain
                class="bg-t w-100"
                >{{ $t("recharge.uv") }}</el-button
              >
            </el-upload>
          </el-form-item>
          <el-form-item v-if="formDataBank.image" class="d-flex-center">
            <el-image
              class="mx-auto voucher-img"
              :src="formDataBank.image"
              fit="cover"
              :preview-src-list="srcList"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-s-promotion"
              class="w-100"
              native-type="button"
              :loading="loading1"
              @click="submitBank"
              >{{ $t("fiat_currency.dialog2.submitBank") }}</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 对公转帐窗口 -end-->
    </div>
  </div>
</template>

<script>
import coinLogo from "@/assets/coin/logo";
import aimg from "@/assets/fiat/hpic.png";

export default {
  data() {
    var checkMoney = (rule, value, callback) => {
      let that = this
      if (!value) {
        callback(
          new Error(that.$t("money_error.text1"))
        );
      } else {
        let Reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
        if (!Reg.test(value)) {
          callback(
            new Error(
              that.$t("money_error.text2")
            )
          );
        }
        callback();
      }
    };

    return {
      aimg,

      // banner: this.$t("home.banner"),
      coinLogo,
      tabid: 2,//默认对公 2023.3.16
      noticeList: [],
      marketOld: null,
      market: [],
      showUp: false,
      showDown: false,
      loading: false,
      loading1: false,
      timer: null,
      dataVersion: 0,

      //新增
      tradingLoading: false,
      page: 1,
      pageSize: 10,
      total: 0,
      marketLength: 0,
      socket1: null,

      dialogVisible: false,
      btn_load: false,

      load1: false,
      load2: false,
      load3: false,
      dialogVisible1: false,

      // //USDT快捷賣出
      // formData: {
      //   coin: "",
      //   currency: "", //暂时固定
      //   bank_id:'',
      // },

      //改为法币在线支付
      formData: {
        coin: "",
        cointext: "",
        money: "",
        rate:"",
        all:"",
      },

      srcList: [],
      formDataBank: {
        image: "",
        image_fileName: "",
        channel: "corporate account",
      },
      rules: {
        coin: [
          {
            required: true,
            message: this.$t("money_error.text1"),
            trigger: "blur",
          },
        ],
        money: [
          { validator: checkMoney, trigger: "blur" },
        ],
        // bank_id: [
        //   {
        //     required: true,
        //     message: this.$t("fiat_currency.rules.message2"),
        //     trigger: "blur",
        //   },
        // ],
      },
      rules3: {
        money: [
          // {
          //   required: true,
          //   message: this.$t("fiat_currency.rules.message3"),
          //   trigger: "blur",
          // },
          { validator: checkMoney, trigger: "blur" },
        ],
        image: [
          {
            required: true,
            message: this.$t("fiat_currency.rules.message4"),
            trigger: "blur",
          },
        ],
      },

      dialog1: false,
      dialog2: false,
      buyDetail: {},
      nickname: "",
      pay_for: 0,
      buynum: "",

      rate: [],
      bank_list: [],

      pub: {},

      //公賬驗證狀態
      corporate_account_power: "",
    };
  },
  watch: {},
  created: function () {
    this.getTraderMarket();
    this.getRecycleExchangeRate();
    // this.bankList();

    //獲取公賬數據
    // this.getCorporateAccount()
    this.verifyFn();
  },
  beforeDestroy: function () {},
  destroyed: function () {},
  methods: {
    onTab: function (active) {
      this.tabid = active;
    },

    // //修改币种,快捷卖出
    // changeFnCoin(e){
    //   console.log(e)
    //   // this.formData.coin = "TWD" + ":" + this.rate["TWD"];
    //   this.formData.coin = this.rate[e].name_text
    //   // this.formData.bank_id = ''
    //   this.bankList(this.rate[e].name)

    //   if(this.formData.money){
    //     this.formData.all = this.floatingpoint.mul(
    //       Number(this.formData.money),
    //       Number(this.formData.coin.split(":")[1])
    //     );
    //   }
    // },

    //修改币种 法币在线支付
    changeFnCoin(e){
      this.formData.coin = this.rate[e].name
      this.formData.cointext = this.rate[e].name_text
      this.formData.rate = this.rate[e].rate
      if(this.formData.money){
        this.formData.all = this.floatingpoint.mul(
          Number(this.formData.money),
          Number(this.formData.rate)
        );
      }
    },


    //申請公賬驗證
    corporateFn() {
      let that = this;
      this.$request.post("v1/user/corporate_account_power").then((data) => {
        console.log(data)
        that.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: data.message,
          onClose: function () {
            that.verifyFn();
          },
        });
      });
    },

    //請求實名認證接口獲取請求公賬驗證狀態
    verifyFn: function () {
      let that = this;
      this.$request.post("v1/user/verify").then(({ data }) => {
        // that.corporate_account_power = data.corporate_account_power;
        // if (that.corporate_account_power == 3) {
        //   //獲取公賬
        //   that.getCorporateAccount();
        // }
        // 3.17停止公账权限验证
        let _this = this;
        if (data.verified != 3) {
          //实名未提交,失敗等跳转实名页面
          let text_notice = "";
          if (data.verified == 0) {
            // text_notice = "您未提交實名認証，請先提交實名認証";
            text_notice = _this.$t("contract_shenqin.text_notice1");
          } else if (data.verified == 1) {
            // text_notice = "您的實名認証失敗，請重新提交實名認証";
            text_notice = _this.$t("contract_shenqin.text_notice2");
          } else if (data.verified == 2) {
            // text_notice = "您的實名認証正在審核中";
            text_notice = _this.$t("contract_shenqin.text_notice3");
          }

          if (data.verified == 0 || data.verified == 1) {
            return this.$confirm(text_notice, _this.$t("contract_shenqin.tips"), {
              confirmButtonText: _this.$t("contract_shenqin.confirmButtonText"),
              cancelButtonText: _this.$t("contract_shenqin.cancelButtonText"),
              type: "warning",
            })
              .then(() => {
                console.log("用戶點擊確定，实名");
                _this.$nav.push("/user/settings/verify");
              })
              .catch(() => {
                console.log("用戶點擊取消，实名");
              });
          } else {
            return this.$message({
              showClose: true,
              type: "Warning",
              duration: 2000,
              message: text_notice,
              onClose: function () {},
            });
          }
        }else{
          that.getCorporateAccount();
        }
      });
    },

    //自选交易列表
    getTraderMarket: function () {
      this.tradingLoading = true;
      this.$request
        .post("v1/deal/getTraderMarket", {
          page: this.page,
          pageSize: this.pageSize,
        })
        .then(({ data }) => {
          this.market = data.list;
          this.total = data.total;
          this.tradingLoading = false;
        });
    },

    //获取银行卡
    bankList: function (e) {
      this.formData.bank_id = ''
      this.bank_list = []
      this.$request.post("v1/bank/getList",{coin:e}).then(({ data }) => {
        this.bank_list = data;
      });
    },

    //获取公账
    getCorporateAccount: function () {
      this.$request.post("v1/deal/getCorporateAccount").then(({ data }) => {
        this.pub = data;
      });
    },

    //获取币种、汇率(改为法币币种汇率)
    getRecycleExchangeRate: function () {
      this.$request.get("v1/deal/getFrenchCurrency", {}).then((data) => {

        var arr = []
        for(var key in data.data){
          arr.push({id:key,name:data.data[key].coin,name_text:data.data[key].coin+' '+'(USDT:'+data.data[key].coin+' = 1:'+data.data[key].rate+')',rate:data.data[key].rate})
        }

        this.rate = arr
      
      });
    },

    //计算出售总金额
    moneyFn(e) {
      if(this.formData.coin){
        this.formData.all = this.floatingpoint.mul(
          Number(e),
          Number(this.formData.rate)
        );
      }
    },

    //计算实付款
    buynumFn(e) {
      //实际支付向上取整
      this.pay_for = Math.ceil(this.floatingpoint.mul(
        Number(this.buynum),
        Number(this.buyDetail.offer)
      ))
    },

    //全部购买
    allbuyFn() {
      this.buynum = this.buyDetail.balance;
      this.pay_for = Math.ceil(this.floatingpoint.mul(
        Number(this.buynum),
        Number(this.buyDetail.offer)
      )) 
    },

    pageFn1(e) {
      this.page = e;
      this.getTraderMarket();
    },

    open(e) {
      if (!this.$store.state.userToken) {
        return this.$confirm(
          this.$t("fiat_currency.message.p_userToken"),
          this.$t("fiat_currency.message.tips"),
          {
            distinguishCancelAndClose: true,
            confirmButtonText: this.$t("fiat_currency.message.confirm"),
            cancelButtonText: this.$t("fiat_currency.message.cancel"),
          }
        )
          .then(() => {
            this.$router.push("../user/login");
          })
          .catch((action) => {});
      }
      if (this.$store.state.userVerified != 3) {
        return this.$confirm(
          this.$t("fiat_currency.message.p_userVerified"),
          this.$t("fiat_currency.message.tips"),
          {
            distinguishCancelAndClose: true,
            confirmButtonText: this.$t("fiat_currency.message.confirm"),
            cancelButtonText: this.$t("fiat_currency.message.cancel"),
          }
        )
          .then(() => {
            this.$router.push("../user/settings/verify");
          })
          .catch((action) => {});
      }

      this.dialogVisible = true;
      this.dialog1 = true;
      this.buyDetail = e;
      this.dialog1 = false;
      // this.$request
      //   .post("v1/deal/getTraderMarketInfo", {
      //     id: e,
      //   })
      //   .then(({ data }) => {
      //     console.log(data);
      //     this.dialog1 = false;
      //   });
    },


    //兌入購買
    comeFn() {
      let that = this;
      if (!this.pay_for) {
        return this.$message({
          message: that.$t("fiat_currency.rules.err_message1"),
          showClose: true,
          duration: 2000,
          type: "error",
        });
      }
      let Reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
      if (!Reg.test(this.pay_for)) {
        return this.$message({
          message:
            localStorage.getItem("locale") == "zh-TW"
              ? "只能保留兩位小數"
              : "Only two decimal places",
          showClose: true,
          duration: 2000,
          type: "error",
        });
      }
      if (
        Number(this.pay_for) > Number(this.buyDetail.max_money) ||
        Number(this.pay_for) < Number(this.buyDetail.min_money)
      ) {
        return this.$message({
          message: that.$t("fiat_currency.rules.err_message2"),
          showClose: true,
          duration: 2000,
          type: "error",
        });
      }
      if (Number(this.buynum) > Number(this.buyDetail.balance)) {
        return this.$message({
          message: that.$t("fiat_currency.rules.err_message3"),
          showClose: true,
          duration: 2000,
          type: "error",
        });
      }

      // 點擊前先判斷有無存在待處理訂單
      that.$request.post("v1/deal/getTraderMarketInfo", {
        id: that.buyDetail.id,
      }).then((res)=>{
        that.$router.push({
          path: "buy",
          query: {
            id: that.buyDetail.id,
            price: that.pay_for,
            amount: that.buynum,
            price_sin: that.buyDetail.offer,
          },
          // name:'buy',
          // params:{
          //   id:this.buyDetail.id,
          //   price:this.pay_for,
          // }
        });
      })

      // // 原本直接跳
      // this.$router.push({
      //   path: "buy",
      //   query: {
      //     id: this.buyDetail.id,
      //     price: this.pay_for,
      //     amount: this.buynum,
      //     price_sin: this.buyDetail.offer,
      //   },
      //   // name:'buy',
      //   // params:{
      //   //   id:this.buyDetail.id,
      //   //   price:this.pay_for,
      //   // }
      // });

      // this.$nav.push("buy?id=" + this.buyDetail.id+'&price='+this.pay_for);
    },

    //USDT快捷賣出
    // submitForm: async function () {
    //   if (!this.$store.state.userToken) {
    //     return this.$confirm(
    //       this.$t("fiat_currency.message.p_userToken"),
    //       this.$t("fiat_currency.message.tips"),
    //       {
    //         distinguishCancelAndClose: true,
    //         confirmButtonText: this.$t("fiat_currency.message.confirm"),
    //         cancelButtonText: this.$t("fiat_currency.message.cancel"),
    //       }
    //     )
    //       .then(() => {
    //         this.$router.push("../user/login");
    //       })
    //       .catch((action) => {});
    //   }
    //   try {
    //     await this.$refs.ruleForm.validate();
    //     this.loading = true;
    //     let that = this;

    //     let $dataJSON = {};
    //     $dataJSON.money = this.formData.money;
    //     $dataJSON.bank_id = this.formData.bank_id;
    //     // $data.currency = this.formData.currency;
    //     $dataJSON.currency = this.formData.coin.split(":")[0];

    //     let $data = await this.$request.post("v1/deal/recycle", $dataJSON);

    //     this.$message({
    //       showClose: true,
    //       type: "success",
    //       duration: 2000,
    //       message: $data.message,
    //       onClose: function () {
    //         // that.formData.coin = 'TWD'+':'+that.rate['TWD']
    //         // that.formData.currency='TWD'
    //         that.loading = false;
    //         that.$router.push("/user/account/recycle");
    //       },
    //     });
    //   } catch (error) {
    //     console.log(error);
    //     this.loading = false;
    //   }
    // },

    //法币在线支付提交
    submitForm: async function () {
      if (!this.$store.state.userToken) {
        return this.$confirm(
          this.$t("fiat_currency.message.p_userToken"),
          this.$t("fiat_currency.message.tips"),
          {
            distinguishCancelAndClose: true,
            confirmButtonText: this.$t("fiat_currency.message.confirm"),
            cancelButtonText: this.$t("fiat_currency.message.cancel"),
          }
        )
          .then(() => {
            this.$router.push("../user/login");
          })
          .catch((action) => {});
      }
      try {
        await this.$refs.ruleForm_fiat.validate();
        this.loading = true;
        let that = this;

        let $dataJSON = {};
        $dataJSON.money = this.formData.money;
        $dataJSON.coin = this.formData.coin;

        let $data = await this.$request.post("v1/deal/pay", $dataJSON);
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.loading = false;
            // that.$router.push("/user/account/fiat");
            window.open($data.data.url,"_blank");    
            that.formData = {
              coin: "",
              cointext: "",
              money: "",
              rate:"",
              all:"",
            }   
          },
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    //对公转账窗口
    bankFn() {
      if (!this.$store.state.userToken) {
        return this.$confirm(
          this.$t("fiat_currency.message.p_userToken"),
          this.$t("fiat_currency.message.tips"),
          {
            distinguishCancelAndClose: true,
            confirmButtonText: this.$t("fiat_currency.message.confirm"),
            cancelButtonText: this.$t("fiat_currency.message.cancel"),
          }
        )
          .then(() => {
            this.$router.push("../user/login");
          })
          .catch((action) => {});
      }
      if (this.$store.state.userVerified != 3) {
        return this.$confirm(
          this.$t("fiat_currency.message.p_userVerified"),
          this.$t("fiat_currency.message.tips"),
          {
            distinguishCancelAndClose: true,
            confirmButtonText: this.$t("fiat_currency.message.confirm"),
            cancelButtonText: this.$t("fiat_currency.message.cancel"),
          }
        )
          .then(() => {
            this.$router.push("../user/settings/verify");
          })
          .catch((action) => {});
      }
      this.dialogVisible1 = true;
    },

    //转公账提交
    submitBank: async function () {
      try {
        await this.$refs.ruleForm1.validate();
        this.loading1 = true;

        let that = this;
        let _data = {};
        _data = that.formDataBank;
        _data.image = that.formDataBank.image_fileName;
        // _data.money = this.floatingpoint.mul(_data.money, 100);
        // this.floatingpoint.mul
        _data.coin='USDT';
        _data.wallet_type=1;
        let $data = await this.$request.post("v1/order/recharge", _data);
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.formDataBank = {};
            that.formDataBank.image = "";
            that.formDataBank.image_fileName = "";
            that.loading1 = false;
            that.$nav.push("/user/account/recharge");
          },
        });
      } catch (error) {
        this.loading1 = false;
      }
    },

    //上传图片
    onUploadVoucher: function (file) {
      if (file.size > 1024 * 1024 * 5) {
        this.$message({
          message: that.$t('imageLimit'),
          type: "error",
        });
        return false;
      }
      var that = this;
      // var reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = function () {
      //   // console.log(reader.result); //获取到base64格式图片
      //   that.formDataBank.image = reader.result;
      //   that.srcList = [that.formDataBank.image];
      // };

      let sendData = new FormData();
      if (file.size > 1024 * 1024 * 2) {
        this.$imgCompress.imgCompress(file)
        .then(f => {
          sendData.append("image", f);
          that.$request
            .post("v1/uploadImage", sendData)
            .then(({ data }) => {
              that.formDataBank.image = data.url;
              that.formDataBank.image_fileName = data.fileName;
              that.srcList = [that.formDataBank.image];
            })
            .catch(() => {});
        })
      }else{
        sendData.append("image", file);
        that.$request
          .post("v1/uploadImage", sendData)
          .then(({ data }) => {
            that.formDataBank.image = data.url;
            that.formDataBank.image_fileName = data.fileName;
            that.srcList = [that.formDataBank.image];
          })
          .catch(() => {});
      }

      // let sendData = new FormData();
      // sendData.append("image", file);
      // that.$request
      //   .post("v1/uploadImage", sendData)
      //   .then(({ data }) => {
      //     that.formDataBank.image = data.url;
      //     that.formDataBank.image_fileName = data.fileName;
      //     that.srcList = [that.formDataBank.image];
      //   })
      //   .catch(() => {});
    },
  },
};
</script>
<style scoped>
.index-top {
  /* background-image: url(~@/assets/index/top-bg.png); */
  height: 300px;
  background-repeat: no-repeat;
  background-size: auto 460px;
  background-position: center;
}
.icon-style-yuan {
  border-radius: 50%;
}
</style>
<style lang="scss" scoped>
.notice-bar {
  .link {
    display: inline-block;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tabs {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $bgColor;
  .tab {
    padding-bottom: 18px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  .active {
    color: $--color-primary;
    border-bottom: 2px solid $--color-primary;
  }
}
.market-body {
  .change {
    font-size: 14px !important;
    width: 80px;
    text-align: center !important;
  }
}
.services {
  .item {
    img {
      width: 100px;
      height: 100px;
    }
    p {
      font-size: 14px;
    }
  }
}

// 自选交易列表
.dealers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dealers_img {
  width: 30px;
  height: 30px;
}
.dealers span {
  padding: 0 10px;
  box-sizing: border-box;
}
.bank_card {
  display: inline-block;
  // align-items: center;
  // justify-content: flex-start;
  padding: 1px 7px;
  border: 1px solid #666;
  border-radius: 3px;
  // vertical-align: middle;
  color: #666;
}
.bank_card i {
  font-size: 16px;
  line-height: 20px;
  vertical-align: middle;
}
.bank_card span {
  font-size: 12px;
  line-height: 20px;
  margin-left: 3px;
  vertical-align: middle;
}
.price_style {
  font-weight: bold;
  color: #f9a825;
}
.pagination-outline {
  text-align: center;
  padding: 20px 0;
  box-sizing: border-box;
}

// 弹窗样式
.buy_content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.buy_content_left {
  width: 50%;
  padding-right: 20px;
  box-sizing: border-box;
}
.buy_content_right {
  width: 50%;
  border: 1px solid #e3e6e9;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
}
.buy_content_right_item1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.right_item_col:nth-child(1) {
  width: 30%;
}
.right_item_col:nth-child(2) {
  width: 50%;
}
.right_item_col:nth-child(3) {
  width: 20%;
  text-align: right;
}
.right_item_col:nth-child(3) .span2 {
  color: #f9a825;
  font-weight: bold;
}
.right_item_col span {
  display: block;
  font-size: 12px;
}
.right_item_col .span1 {
  margin-bottom: 5px;
}
.buy_content_mes {
  font-size: 12px;
}
.buy_content_right_item2_top {
  position: relative;
  margin-bottom: 5px;
}
.buy_content_right_item2_top input {
  width: 100%;
  height: 36px;
  border: 1px solid #f9a825;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 150px 0 10px;
}
.inp-pos {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  right: 10px;
  top: 8px;
}
.inp-pos span {
  line-height: 20px;
}
.inp-pos span:nth-child(2) {
  margin: 0 5px;
  width: 1px;
  height: 12px;
  display: block;
  background: #666;
}
.inp-pos span:nth-child(3) {
  color: #f9a825;
  cursor: pointer;
}
.buy_content_right_item2 {
  margin-bottom: 20px;
}
.buy_content_right_item2_top input::placeholder {
  font-size: 12px;
  font-weight: normal;
  font-family: Arial;
  // letter-spacing: 5px;
  // color: red !important;
  // text-align: center;
}
.buy_content_right_item2_btm {
  font-size: 12px;
  text-align: right;
}
.buy_content_right_item2_btm span {
  margin-right: 5px;
}
.buy_content_right_item3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

//USDT快捷賣出
.box2 {
  padding: 40px 0;
  box-sizing: border-box;
}
.box-form {
  width: 34%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
  // border: 1px solid #e3e6e9;
  border-radius: 5px;
}
.label {
  font-size: 14px;
  line-height: 30px;
  color: #666;
}
.label span {
  color: #f56c6c;
}
.recycling_tips {
  font-size: 12px;
}

// 对公转账
.public_account dt {
  text-align: center;
  margin-bottom: 20px;
}
.public_account dl,
.public_account dd {
  margin: 0;
}
.public_account h5 {
  margin: 0;
  width: 35%;
  line-height: 20px;
  font-size: 14px;
  color: #f9a825;
}
.public_account p {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  width: 65%;
  text-align: right;
}
.public_account dd {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #f3f6f9;
}
.public_account dd:nth-last-child(1) {
  border: 0;
}
.public_account {
  margin-bottom: 20px;
}

.public_account_new h3 {
  text-align: center;
}
.public_account_new p {
  text-align: center;
  width: 100%;
  font-size: 16px;
}
</style>